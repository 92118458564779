.dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--dropdown);
  width: 100%;
  padding: 0 1.5rem;
  margin-top: 1.125rem;

  .dropdownItem {
    color: var(--grey-f3);
    padding: 1.5rem 0;
    width: 100%;
    cursor: pointer;

    .total {
      margin-left: 0.3rem;
      font-weight: 300;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--white-t2);
    }
  }
}
.icon {
  width: 0.5rem;
}

.isLast {
  padding: 0 0 0 1.5rem;
  margin-top: 1rem;
}
